<template>
  <page-section
      :title="$t('wsu_homepage.cases.title')"
      :subtitle="$t('wsu_homepage.cases.subtitle')"
  >
    <v-row class="mt-12">
      <v-col cols="12" md="3" v-for="(icon, item ) in casesSelect" :key="item" >
        <v-icon large :color="wsATTENTION">
<!--          {{ icon }}-->
          mdi-check
        </v-icon>
        <h3 style="font-size: 19px" :style="`color : ${wsDARKER}`" class="mt-7">
          {{ $t(`wsu_homepage.cases.${item}.title`) }}
        </h3>
        <h4 class="font-weight-regular mt-2 mb-10">
          {{ $t(`wsu_homepage.cases.${item}.text`) }}
        </h4>
      </v-col>
    </v-row>
  </page-section>
</template>

<script>
export default {
  name: "homeSectionCases",
  data() {
    return {
      casesSelect : {
        virtual_university : 'mdi-cog',
        refuges : 'mdi-cog',
        courses : 'mdi-cog',
        education_support : 'mdi-cog',
        courses_access : 'mdi-cog',
        education_process_support : 'mdi-cog',
        security : 'mdi-cog',
        management : 'mdi-cog',
      }
    }
  }
}
</script>

<style scoped>

</style>